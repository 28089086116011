@media only screen and (min-width: 1280px) {
  body {
    background: url("/img/4desktop.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #f0f0f0;
  }

  .desktop_container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .tablet_container {
    background-size: contain;
    flex-flow: row;
    height: unset;
  }

  .sub_container {
    justify-content: center;
    flex-shrink: 1;
  }

  .middle_column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .painting_image {
    padding-top: 100px;
  }

  .second_input,
  .contact,
  .blob,
  .content .title,
  .cta,
  .tablet_icons,
  .middle_row,
  .error_two,
  .tablet_header,
  .tablet_footer {
    display: none;
  }

  .logo {
    display: inline;
  }

  .desktop_header {
    padding: 25px 0 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .desktop_header .images {
    display: flex;
    justify-content: space-around;
    width: 100vw;
    height: 23.5px;
  }

  .header_title {
    display: inline;
    margin-top: 20px;
    font-size: 28px;
  }

  .desktop_footer {
    display: flex;
    flex-flow: column;
    padding-top: 12vh;
  }

  .subtitle {
    flex-direction: column;
  }

  .subtitle p {
    line-height: 40px;
    font-size: 25px;
    text-align: center;
    padding: 0;
  }

  .input {
    padding-top: 5px;
  }

  .flex_right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .flex_left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .same_line {
    display: flex;
    flex-wrap: nowrap;
  }

  .teaser_text {
    text-align: center;
  }

  .teaser_text p {
    font-size: 21px;
    line-height: 26px;
  }

  .teaser_text p span {
    font-size: 21px;
    line-height: 26px;
  }

  @media only screen and (max-height: 690px) {
    .header_title {
      font-size: 25px;
    }

    .subtitle p {
      line-height: 29px;
      font-size: 21px;
    }

    .teaser_text p {
      font-size: 18px;
      line-height: 18px;
    }

    .teaser_text p span {
      font-size: 18px;
      line-height: 26px;
    }

    .tablet_container {
      height: 50%;
    }

    .painting_image {
      padding-top: 0;
    }

    .middle_column img,
    .desktop_icons img {
      height: 20vh;
    }
  }
}
